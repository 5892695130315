<template>
  <modal
      @close="$emit('close')"
      class="custom-popup request-payment-popup"
      :bodyZPosition="true"
  >
    <template slot="header">
      <div
          v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['returnGoods_ReturnedGoods',])"></div>
        {{$t('returnGoods_ReturnedGoods.localization_value.value')}}
        #{{ undefinedReturnPackageId || requestReturnPackageId }}
      </div>

    </template>
    <template slot="body">
      <div class="custom-popup__content"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit"
              @click="editTranslate([
              'payments_transaction',
              'unidentifiedP_messageToAdmin',
              'common_cancel',
              'common_send',
              ])"></span>
        <div class="section-label large soft-text"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['returnGoods_ReturnedGoods',])"></div>
          {{$t('returnGoods_ToRequest.localization_value.value')}}
        </div>
        <div class="custom-row">
          <div class=" custom-col"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['returnGoods_messageToAdmin',])"></div>
            <TextareaDefault
                :label="$t('returnGoods_messageToAdmin.localization_value.value')"
                :type="'text'"
                v-bind:class="{'ui-no-valid': RRG.validation.comment}"
                :errorTxt="$t(`${RRG.validationTranslate.comment}.localization_value.value`)"
                :error="RRG.validation.comment"
                v-model="RRG.data.comment"
            />
          </div>
          <div class=" custom-col">
            <div class="custom-row" :key="images - 100">
              <div class="custom-col custom-col--25 custom-col--md-50 custom-col--sm-100"
                   v-for="(item, index) in RRG.data.Files.data.downloadFiles"
                   :key="index">
                <div class="site-document d-flex justify-content-center">
                  <div class="site-document__img">
                    <img v-if="item.mime_type !== 'application/pdf'"
                         :src="`data:${item.type};base64,` + item.base64"
                         @click="showSingle(item.id + 1)"
                         alt="img"
                    >
                    <img v-else
                         @click="openPdf(item['base64'])"
                         style="width: 30px; height: 40px;"
                         src="/img/common/pdf_file_icon.svg"
                         alt="ico"
                    >
                  </div>
                </div>
              </div>

              <div class="custom-col"
                   v-if="RRG.data.Files.data.maxCountFiles > RRG.data.Files.data.downloadFiles.length && undefinedReturnPackageId">
                <DropZone class="drop-zone-bg"
                          :parentFiles="RRG.data.Files.data.files"
                          :multiple="true"
                          :maxCount="RRG.data.Files.data.maxCountFiles - RRG.data.Files.data.downloadFiles.length"
                          :maxSize="RRG.data.Files.data.maxSizeFiles"
                          :accept="'image/x-png,image/gif,image/jpeg,application/pdf'"
                          :error="RRG.data.Files.validation.files"
                          :errorTxt="$t(`error_required.localization_value.value`)"
                          @changeImg="changeImg"
                />
              </div>
            </div>
          </div>
        </div>

        <Lightbox
            escDisabled
            moveDisabled
            :visible="visible"
            :imgs="imgs"
            :index="index"
            @hide="handleHide"
        ></Lightbox>
      </div>
    </template>
    <template slot="footer">
      <div class="import-transaction__btn d-flex align-items-center flex-wrap">
        <span
            class="site-link site-link--alt import-transaction__btn-i mt-3 mr-3 mb-3"
            @click="$emit('close')"
        >
          {{ $t('common_cancel.localization_value.value') }}
        </span>

        <MainButton
            v-if="undefinedReturnPackageId"
            :value="$t('common_send.localization_value.value')"
            class="import-transaction__btn-i"
            v-bind:class="{'disabled-btn' : $store.getters.getRequestReturnGoodsBtn}"
            @click.native="save"
        />
      </div>
    </template>
  </modal>
</template>

<script>
import Modal from '../../../../commonModals/Modal.vue'
import MainButton from '../../../../UI/buttons/MainButton/MainButton.vue'
import DropZone from "../../../../coreComponents/DropZone/DropZone";
import TextareaDefault from "../../../../UI/textarea/TextareaDefault/TextareaDefault";
import {RequestReturnGoods} from "@/components/modules/ReturnGoodsModule/components/models/RequestReturnGoods";
import Lightbox from "vue-easy-lightbox";
import {returnGoodsMixin} from "@/mixins/returnGoodsMixins/returnGoodsMixin";

export default {
  name: "RequestReturnPackagePopup",
  components: {
    Modal,
    MainButton,
    DropZone,
    TextareaDefault,
    Lightbox,
  },

  mixins: [returnGoodsMixin],

  props: {
    item: {
      type: Object,
    },
    requestReturnPackageId: {
      type: Number,
      default: null,
    },
    undefinedReturnPackageId: {
      type: Number,
      default: null,
    },
  },

  data() {
    return {
      RRG: new RequestReturnGoods(),
    }
  },

  mounted() {
    if (this.requestReturnPackageId) {
      this.$store.dispatch('getRequestReturnGoods', this.requestReturnPackageId).then(response => {
        let dataResponse = this.getRespData(response)
        this.RRG.setItem(dataResponse)

        let files = dataResponse?.file_entities

        this.downloadFileFromArray(files).then((documents) => {
          let filesObj = this.RRG.data.Files
          filesObj.setDownloadFiles(documents)
        }).catch(error => this.createErrorLog(error))
      }).catch(error => this.createErrorLog(error))
    }
  },

  methods: {

    save() {
      if (!this.RRG.firstValidation()) return

      let data = this.RRG.prepareSave()

      data['return_parcel_id'] = this.undefinedReturnPackageId

      this.$store.dispatch('createRequestReturnGoods', data).then(response => {
        if (!this.getResponseStatus(response)) return this.openNotify('error', 'common_notificationUndefinedError')

        switch (this.getResponseStatus(response)) {
            /**
             * Success
             */
          case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
            this.addFiles(this.getRespData(response)?.id).then(() => {
              this.$emit('reload')
              this.$emit('close')
            })
            break
          }
            /**
             * Validation Error
             */
          case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
            let errors = response.response.data.errors;
            this.notifyErrorHelper(errors)
            break
          }
            /**
             * Undefined Error
             */
          default: {
            this.openNotify('error', 'common_notificationUndefinedError')
          }
        }
      })

    },

    async addFiles(id) {
      if (this.RRG.data.Files.getFiles().length > 0) {
        await this.addCaseFiles(id)
      }
      this.openNotify('success', 'common_notificationRecordCreated')
    },


    async addCaseFiles(id) {
      let data = this.RRG.prepareFilesData(id)

      if (data.length === 0) return Promise.resolve()


      for (const file of data) {
        await this.$store.dispatch('addRequestReturnGoodsFile', file).then(response => {
          this.responseFilesProcessing(response)
          return response
        })
      }
    },

    responseFilesProcessing(response) {
      if (!this._.has(response, 'data')) return this.openNotify('error', 'common_notificationUndefinedError')
      switch (this.getResponseStatus(response)) {
          /**
           * Success
           */
        case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
          break
        }
          /**
           * Validation Error
           */
        case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
          let errors = response.response.data.errors;
          this.notifyErrorHelper(errors)
          break
        }
          /**
           * Undefined Error
           */
        default: {
          this.openNotify('error', 'common_notificationUndefinedError')
        }
      }
    },


    changeImg(files, maxSizeError) {
      this.RRG.data.Files.setFiles(files)

      this.RRG.data.Files.setFilesMaxSizeError(maxSizeError)
    },

  }
}

</script>

<style lang="scss">
.request-payment-popup {

  .modal-component__inner {
    max-width: 685px;
  }
}


</style>

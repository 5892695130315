<template>
  <div class="fragment">

    <div class="content-top-line position-relative">
      <div class="content-tabs content-tabs--separator">
        <template v-for="(item, index) in navTabs">
          <div class="content-tabs__item"
               :key="index"
               v-bind:class="{active: item.active}"
               @click="$emit('changeTab', item.name)"
          >
            <div class="content-tabs__ico"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <!--              <span class="admin-edit" @click="editTranslate([item.label])"></span>-->
              <template>
                {{item.label}}
              </template>
            </div>
          </div>
        </template>
      </div>

      <div class="content-top-line-wrap">
        <ToggleFilterButton :count="countFilterParams" @toggleFilter="$emit('toggleFilter')" :showFilter="showFilter"/>
      </div>
    </div>

    <div class="light-bg-block">
    </div>
  </div>
</template>

<script>
import ToggleFilterButton from "../../../../../../UI/buttons/ToggleFilterButton/ToggleFilterButton";


export default {
  name: "UnidentifiedReturnsHead",
  components: {
    ToggleFilterButton,
  },

  props: {
    showFilter: Boolean,
    countFilterParams: Number,
    navTabs: Object,
  },

  data() {
    return {}
  },

  methods: {}
}
</script>

<style scoped>

</style>


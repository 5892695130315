<template>
  <UnidentifiedReturnsListUser
      :countFilterParams="countFilterParams"
      :filterGetParams="filterGetParams"
      :navTabs="navTabs"
      @resetFilter="resetFilter"
      @showMore="showMore"
      @changeFilter="changeFilter"
      @reload="reload"
      @changeTab="changeTab"
  />
</template>

<script>
  import UnidentifiedReturnsListUser from "./UnidentifiedReturnsListUser/UnidentifiedReturnsListUser";
  import {routeFilter} from "@/mixins/routeFilterMixins/routeFilter";
  //import {BLAINE_WAREHOUSE, LODZ_WAREHOUSE, CAMBRIDGE_WAREHOUSE} from "../../../../../staticData/staticVariables";

  export default {
    name: "UnidentifiedReturnsList",
    components: {
      UnidentifiedReturnsListUser,
    },

    mixins: [routeFilter],

    data(){
      return {
        forPage: this.$store.getters.getReturnGoodsForPage,
        page: 1,
        onePage: 1,
        filterDate: [],
        filterId: '',
        filterUserId: '',
        filterUserName: '',
        filterTrackingNumber: '',
        filterOriginTrackingNumber: '',
        filterWithRequestReturn: '',
        filterType: '',

        countFilterParams: 0,
        filterGetParams: {},

        navTabs: {
        },
      }
    },

    mounted() {
      if (this.isAdmin) {
        this.getWarehouseStorages().then(() => {
          if(this.$route.query.type !== undefined){
            this.skipRadioChecked(this.navTabs, 'active')
            this.navTabs[this.$route.query.type].active = true
          }
          else {
            this.$route.query.type = this._.find(this.navTabs, {active: true})?.name || ''
          }
          this.filter()
        })
      }
      else {
        this.filter()
      }
    },

    methods: {

      getFilterParam() {
        this.filterGetParams = {
          page: this.$route.query.page,
          count: this.$route.query.count,
          date: this.$route.query.date,
          userName: this.$route.query.userName,
          userId: this.$route.query.userId,

          id: this.$route.query.id,
          trackingNumber: this.$route.query.trackingNumber,
          originTrackingNumber: this.$route.query.originTrackingNumber,
          withRequestReturn: this.$route.query.withRequestReturn,
          type: this.$route.query.type,
        }
      },

      filter(next = false) {
        this.getFilterParam()

        let url

        // generate main filter if has get params in URL
        let generateMainFilter = this.generateMainFilterFromUrl(next, this)

        // generate other filter if has get params in URL
        this.generateParamsFilterFromUrl(this)

        // Generate filter URL
        url = this.generateFilterUrl(
            generateMainFilter.page,
            generateMainFilter.forPage
        )

        if (next) this.$store.commit('setNextReturnGoodsPage', true)
        this.$store.dispatch('fetchReturnGoods', url).then(() => {
          this.$store.commit('setNextReturnGoodsPage', false)
          this.$store.commit('setGlobalUpdateTable', false)
        })


        this.checkCountFilter(['userName', 'type'])
      },

      generateFilterUrl(page, forPage) {
        let myQuery = this.createQueryBuilderObject({withUserNewMark: true})

        this.generateFilterQueryParams(
            myQuery,
            {
              filterId: 'ReturnParcelId',
              filterUserId: 'ReturnParcelUserId',
              filterTrackingNumber: 'ReturnParcelTrackingNumber',
              filterOriginTrackingNumber: 'byStarterTrack',
              filterWithRequestReturn: 'relatedToReturnParcelRequests',
              filterType: 'WarehouseStorageId',
            },
        )

        if (this.filterDate.length > 0) {
          let date = this.generateDateForFilter(this.filterDate, 'YYYY-MM-DD')

          myQuery.whereIn('byDeliveryDate', date)
        }

        myQuery.where('ReturnParcelStatus', 'undefined')

        return this.generateGetParamsFromQuery(myQuery, forPage, page)


      },

      async getWarehouseStorages() {
        return this.$store.dispatch('fetchWarehouseStorage').then((response) => {
          let warehouses = this.getRespPaginateData(response)
          let warehousesLocal = {}
          let activeTab = Number(this.$route.query?.warehouse) || 1

          warehouses.map((item) => {
            let warehouseItem = {}
            warehouseItem['id'] = item.id
            warehouseItem['name'] = item.id
            warehouseItem['label'] = item.name
            warehouseItem['warehouse_id'] = item.warehouse_id
            warehouseItem['active'] = activeTab === item.id
            warehousesLocal[item.id] = this._.clone(warehouseItem)
          })

          this.navTabs = warehousesLocal
        })
      },

    },

    destroyed() {
      setTimeout(() => {
        this.$store.commit('setReturnGoods', {returnGoods: []})
        this.$store.commit('setReturnGoodsCommonList', {returnGoodsCommonList: []})
      }, 100)
    },

  }
</script>

<style scoped>

</style>
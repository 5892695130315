<template>
  <FilterBlock :showFilter="showFilter" :title="$t('common_filterBy.localization_value.value')" @close="closeFilter"
               @changeFilter="changeFilter">
    <template slot="body">
      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['returnGoods_Id'])"></span>
        <DefaultInput
            :label="$t('returnGoods_Id.localization_value.value')"
            :type="'text'"
            v-model="id"
        />
      </div>
      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['returnGoods_shipped'])"></span>
        <DatePickerDefault
            :label="$t('returnGoods_shipped.localization_value.value')"
            v-model="date"
        >
          <template slot="body">
            <date-picker
                v-model="date"
                ref="datePicker"
                valueType="format"
                range
                :lang="$store.getters.getDatePickerTranslations[$store.getters.GET_LANG]"
                :format="'MM/DD/YY'"
                :placeholder="'mm/dd/yy'"
            ></date-picker>
          </template>
        </DatePickerDefault>
      </div>
      <div class="table-filter__item"
           v-if="$store.getters.getIsAdminRights === 'admin'"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['common_user'])"></span>
        <SearchSelect
            :options="options"
            :label="$t('common_user.localization_value.value')"
            :optionsLabel="'email.contacts[0]'"
            :functionSearch="functionSearchUserFilter"
            @change="changeUsersFilter"
            :selected="userName"
            :typeSelect="'users'"
            :userEmail="true"
        />
      </div>


      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['returnGoods_trackingNumber'])"></span>
        <DefaultInput
            :label="$t('returnGoods_trackingNumber.localization_value.value')"
            :type="'text'"
            v-model="trackingNumber"
        />
      </div>


      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['returnGoods_search'])"></span>
        <DefaultInput
            :label="$t('returnGoods_search.localization_value.value')"
            :type="'text'"
            v-model="originTrackingNumber"
        />
      </div>

      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['returnGoods_withRequestReturn'])"></span>
        <DefaultSelect
            :label="$t('returnGoods_withRequestReturn.localization_value.value')"
            :options="withRequestReturnOptions"
            :optionsLabel="'name'"
            :selected="getWithRequestReturn"
            @change="changeWithRequestReturn"
        />
      </div>

    </template>
    <template slot="footer">
      <MainButton @click.native="resetFilter" :value="$t('common_resetFilters.localization_value.value')"
                  class="secondary wfc"/>
      <MainButton @click.native="changeFilter" :value="$t('common_applyFilters.localization_value.value')" class="wfc"/>
    </template>
  </FilterBlock>
</template>

<script>
import FilterBlock from "../../../../../../coreComponents/FilterBlock/FilterBlock";
import MainButton from "../../../../../../UI/buttons/MainButton/MainButton";
import DefaultInput from "../../../../../../UI/inputs/DefaultInput/DefaultInput";
import DatePickerDefault from "../../../../../../UI/inputs/DatePickerDefault/DatePickerDefault";
import DatePicker from 'vue2-datepicker'
import {filterFunc} from "../../../../../../../mixins/filterFuncMixin/filterFunc";
import {usersFunc} from "@/mixins/usersMixins/usersFunc";
import SearchSelect from "@/components/UI/selectiones/SearchSelect/SearchSelect";
import DefaultSelect from "@/components/UI/selectiones/DefaultSelect/DefaultSelect";

export default {
  name: "UnidentifiedReturnsFilter",
  components: {
    DefaultSelect,
    SearchSelect,
    FilterBlock,
    MainButton,
    DatePickerDefault,
    DatePicker,
    DefaultInput,
  },

  mixins: [filterFunc, usersFunc],

  props: {
    navTabs: Object,
    showFilter: Boolean,
    filterGetParams: Object,
    selectHandmade: Array,
  },

  computed: {
    getWithRequestReturn() {
      let foundSelected = this._.find(this.withRequestReturnOptions, {value: this.withRequestReturn})
      return foundSelected ? foundSelected : ''
    },
  },

  data() {
    return {
      date: this.filterGetParams.date ? this.generateFilterDate(this.filterGetParams.date, 'MM/DD/YY') : '',
      id: this.filterGetParams.id ? this.filterGetParams.id : '',
      trackingNumber: this.filterGetParams.trackingNumber ? this.filterGetParams.trackingNumber : '',
      originTrackingNumber: this.filterGetParams.originTrackingNumber ? this.filterGetParams.originTrackingNumber : '',
      withRequestReturn: this.filterGetParams.withRequestReturn ? this.filterGetParams.withRequestReturn : '',

      userName: this.filterGetParams.userName ? this.filterGetParams.userName : '',
      userId: this.filterGetParams.userId ? this.filterGetParams.userId : '',

      options: [],

      filterCounts: [
        'date',
        'id',
        'trackingNumber',
        'originTrackingNumber',
        'withRequestReturn',

        'userName',
        'userId',
      ],

      withRequestReturnOptions: [
        {
          name: this.$t('returnGoods_fromUndefinedReturnAll.localization_value.value'),
          value: ''
        },
        {
          name: this.$t('returnGoods_fromUndefinedReturnYes.localization_value.value'),
          value: '1'
        },
      ],
    }
  },

  watch: {
    filterGetParams(newVal) {
      this.date = newVal.date ? this.generateFilterDate(newVal.date, 'MM/DD/YY') : ''
      this.id = newVal.id ? newVal.id : ''
      this.trackingNumber = newVal.trackingNumber ? newVal.trackingNumber : ''
      this.originTrackingNumber = newVal.originTrackingNumber ? newVal.originTrackingNumber : ''
      this.withRequestReturn = newVal.withRequestReturn ? newVal.withRequestReturn : ''

      this.userName = newVal.userName ? newVal.userName : ''
      this.userId = newVal.userId ? newVal.userId : ''
    },

  },

  methods: {
    closeFilter() {
      this.$emit('close')
    },

    resetFilter() {
      let data = this.resetFilterParams(this, this.filterCounts)

      this.$emit('changeFilter', data)
    },

    changeFilter() {
      let data = this.changeFilterParams(this, this.filterCounts)
      data.date = this.changeDateParams(this, 'date')

      this.$emit('changeFilter', data)
    },

    changeWithRequestReturn(val) {
      this.withRequestReturn = val.value
    },
  },
}
</script>

<style scoped>

</style>

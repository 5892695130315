<template>
  <div class="img-doc-block">

    <div
        v-if="remove"
        class="site-document__remove" @click="$emit('removeDocument')">
		  <CloseIcon/>
		</div>

    <div class="img-doc-block__img"
        @click="$emit('showImgDoc')"
    >
      <slot name="img">

      </slot>
    </div>
    <div class="img-doc-block__content"
      v-if="caption || name"
    >
      <div class="img-doc-block__caption">
        {{caption}}
      </div>

      <div class="img-doc-block__name">
        {{name}}
      </div>
    </div>
  </div>
</template>

<script>
  import CloseIcon from '../../../../public/img/modal-group/close-icon.svg?inline'

  export default {
    name: "ImgDocumentBlock",

    components: {
      CloseIcon,
    },

    props:{
      remove: Boolean,
      caption: String,
      name: String,
    },

    methods: {

    }
  }
</script>

<style lang="scss">
  @import "../../../scss/colors";
  @import "../../../scss/mixins/mixins";

  .img-doc-block{
    position: relative;
    display: flex;
    flex-direction: column;
    width: 137px;

    .site-document__remove {
      right: -19px;
      z-index: 10;
    }

    @include for-992{
      width: 90px;
    }

    &__img{
      height: 137px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      cursor: pointer;

      @include for-992{
        height: 90px;
      }

      &:before{
        content:'';
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        position: absolute;
        background-color: #232323;
        opacity: 0;
        transition: .2s;
      }

      &:after{
        content:'';
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        position: absolute;
        background-image: url('../../../assets/img/common/loop-white-icon.svg');
        background-size: 25px;
        background-repeat: no-repeat;
        background-position: calc(50% - 3px) 50%;
        transition: .2s;
        opacity: 0;
      }

      &:hover{
        &:before{
          opacity: .3;
        }
        &:after{
          opacity: 1;
        }
      }

      img{
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }

    &.w100-h100{
      width: 100%;
      height: 100%;
    }

    &.w100-h100 &__img{
      width: 100%;
      height: 100%;
    }


    &__content{
      display: flex;
      flex-direction: column;
      padding-top: 12px;
      padding-bottom: 12px;
    }

    &__caption{
      font-size: 10px;
      line-height: 12px;
      color: $borderBrown;
      margin-bottom: 5px;

      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    &__name{
      font-size: 14px;
      line-height: 16px;
      color: $black;

      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }

</style>

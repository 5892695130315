<template>
  <div class="case-cart__col custom-col" v-bind:class="[colWidth]">
    <div class="case-cart__label"
         v-if="label"
         v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate(['common_user'])"></div>
      <template v-if="labelText">
        {{$t(`${labelText}.localization_value.value`)}}
      </template>
      <template v-else>
        {{$t('common_user.localization_value.value')}}
      </template>
    </div>
    <div class="case-cart__content" v-if="item.user">
      {{ item.user.user_personal_contact.user_full_name }}
    </div>
  </div>
</template>

<script>
  export default {
    name: "TableUserNameRow",

    props: {
      item: Object,
      colWidth: String,
      label: {
        type: Boolean,
        default: true,
      },
      labelText: {
        type: [String, Boolean],
        default: false
      }
    }
  }
</script>

<style scoped>

</style>

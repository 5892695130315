<template>
  <div>
    <UnidentifiedReturnsHead
        :countFilterParams="countFilterParams"
        :showFilter="showFilter"
        :navTabs="navTabs"
        @toggleFilter="toggleFilter"
        @changeTab="(data) => $emit('changeTab', data)"
        @filter="$emit('filter')"
        @reload="$emit('reload')"
    />

    <div class="table-filter-wrap">

      <UnidentifiedReturnsFilter
          :showFilter="showFilter"
          :filterGetParams="filterGetParams"
          @close="closeFilter"
          @changeFilter="data => $emit('changeFilter', data)"
          @resetFilter="$emit('resetFilter')"
      />

      <UnidentifiedReturnsList
          :countFilterParams="countFilterParams"
          @showMore="$emit('showMore')"
          @resetFilter="$emit('resetFilter')"
          @updateTable="$emit('updateTable')"
          @reload="$emit('reload')"
      />
    </div>
  </div>
</template>

<script>


  import UnidentifiedReturnsHead from "./UnidentifiedReturnsHead/UnidentifiedReturnsHead";
  import UnidentifiedReturnsList from "./UnidentifiedReturnsList/UnidentifiedReturnsList";
  import UnidentifiedReturnsFilter from "./UnidentifiedReturnsFilter/UnidentifiedReturnsFilter";

  export default {
    name: "UnidentifiedReturnsListUser",

    components: {
      UnidentifiedReturnsHead,
      UnidentifiedReturnsList,
      UnidentifiedReturnsFilter,
    },

    props: {
      countFilterParams: Number,
      filterGetParams: Object,
      navTabs: Object,
    },

    data(){
      return{
        showFilter: false,
      }
    },

    watch: {
      filterGetParams(newVal) {
        this.filterGetParams = newVal
      },
      countFilterParams(newVal) {
        this.countFilterParams = newVal
      },
    },

    methods: {
      closeFilter() {
        this.showFilter = false
      },

      toggleFilter() {
        this.showFilter = !this.showFilter
      },

    }
  }
</script>

<style scoped>

</style>



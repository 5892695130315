<template>
  <div class="case-cart">
    <div class="case-cart__inner">
      <div class="case-cart__head">
        <slot name="head">

        </slot>
      </div>

      <div class="case-cart__body">
        <div class="case-cart__columns">
          <div class="case-cart__img-block case-cart__img-block--108 pl-0 pt-0">
            <div class="case-cart__img case-cart__img--108"
                 @contextmenu.prevent="downloadImage()"
                 @click="openImages">
              <ImgDocumentBlock
                  class="w100-h100"
                  :remove="false"
              >
                <template slot="img">
                  <img :src="img" alt="img">
<!--                  <img v-if="downloadImages.length > 0" :src="`data:${downloadImages[0].extension};base64,` + downloadImages[0].base64" alt="img">-->
                </template>
              </ImgDocumentBlock>
            </div>
          </div>
          <slot name="content">

          </slot>
        </div>

        <slot name="contentFooter">

        </slot>
      </div>

      <div class="case-cart__bottom">
        <slot name="footer">

        </slot>
      </div>

    </div>

    <Lightbox
        escDisabled
        moveDisabled
        :visible="visible"
        :imgs="downloadImages"
        :index="index"
        @hide="handleHide"
    ></Lightbox>
  </div>
</template>

<script>
  import ImgDocumentBlock from "../../../../../coreComponents/ImgDocumentBlock/ImgDocumentBlock";
  import Lightbox from 'vue-easy-lightbox'
  import {Files} from "@/components/globalModels/Files";

  export default {
    name: "Item",
    components:{
      ImgDocumentBlock,
      Lightbox,
    },

    props: {
      img: String,
      images: Array,
    },

    data(){
      return{
        imgs: [
          this.img
        ],
        downloadImages: [],
        visible: false,
        index: 0, // default: 0

        Files: new Files(),
      }
    },

    mounted() {

    },

    methods: {

      openImages() {
        this.downloadFileFromArray(this.images).then((documents) => {
          this.Files.setDownloadFiles(documents)
          this.Files.getDownloadFiles().map(downloadFile => {
            this.downloadImages.push(`data:${downloadFile.extension};base64,${downloadFile.base64}`)
            this.showImgDoc(0)
          })

        })
      },

      downloadImage() {
        this.downloadFileFromArray(this.images).then((documents) => {
          this.Files.setDownloadFiles(documents)
          if (this.Files.getDownloadFiles().length > 0) {
            let downloadFile = this.Files.getDownloadFiles()[0]

            const link = document.createElement('a')
            link.href = `data:image/${downloadFile.extension};base64,${downloadFile.base64}`;
            link.download = `Package image ${downloadFile?.big_image ? downloadFile?.big_image : ''}.${downloadFile.extension}`
            link.click()
            URL.revokeObjectURL(link.href)

          }

        })
      },

      async downloadFileFromArray(array) {
        let downloadedFilesArray = []
        for (const item of array) {
          await this.getFileFromServer(item, 'image').then(document => {
            downloadedFilesArray.push(document)
          })
        }
        return downloadedFilesArray
      },

      getFileFromServer(file, type = null){
        if(!file) return

        let localItem = file

        return this.$store.dispatch('getFileFromServer', file.big_image)
            .then((response) => {
              let fileBase64 = response
              switch (type) {
                case 'image':
                  localItem.typeBase64 = `image`
                  localItem.base64 = fileBase64[file.big_image]
                  break
                default:
                  localItem.base64 = fileBase64[file.big_image]
                  break
              }

              return localItem
            })

      },

      changeImg(files) {
        this.files = files
      },

      handleHide() {
        this.visible = false
      },

      showImgDoc(index){
        this.visible = true
        this.index = index
      },
    },
  }
</script>

<style scoped>

</style>